import TextField from '@mui/material/TextField';
import React from 'react';

import { Autocomplete, Button, FormControlLabel, FormGroup } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Snackbar from '@mui/material/Snackbar';
import { ChromePicker } from 'react-color';
import LoadingContainer from '../../../components/loading/LoadingContainer';
import ViewContainer from '../../../view';
// import './StoreEdit.module.css';
import { useParams } from 'react-router-dom';
import BaseComponent from '../../../base';
import Backend from '../../../services/Backend';
import classes from './StoreEdit.module.css';

const withRouter = (WrappedComponent) => (props) => {
	const params = useParams();

	return (
		<WrappedComponent
			{...props}
			params={params}
		/>
	);
};

class StoreEdit extends BaseComponent {
	constructor(props) {
		super(props);

		Backend.fetchBlock().then((blocks) => {
			this.setState({
				blocks: blocks.map((x) => {
					return { ...x, id: x._key, label: x.namn };
				}),
			});
		});

		Backend.fetchChains().then((chains) => {
			this.setState({
				chains: chains.map((x) => {
					return { ...x, id: x._key, label: x.namn };
				}),
			});
		});

		if (props.params.id) {
			Backend.fetchStore(props.params.id).then((res) => {
				if (res.butik.synlig === undefined) {
					res.butik.synlig = true;
				}
				this.setState({
					synlig: res.butik.synlig,
					referensButik: res.butik.referensButik || false,
					loading: false,
					store: res.butik,
					block: (res.block && { id: res.block._key, label: res.block.namn }) || '',
					kedja: (res.kedja && { id: res.kedja._key, label: res.kedja.namn }) || '',
					editExternId: false,
				});
			});
		} else {
			this.state = Object.assign(this.state, {
				loading: false,
				isNew: true,
			});
		}
	}

	state = {
		synlig: true,
		referensButik: false,
		store: {
			synligPrisanalys: true,
		},
		block: null,
		kedja: null,
		blocks: [],
		chains: [],
		isNew: false,
		loading: false,
		snackbar: '',
		editExternId: false,
	};

	validateFields() {
		return !!this.validatePrisGiltighetstid();
	}

	validatePrisGiltighetstid() {
		const value = this.state.store.prisGiltighetstid;
		return value == '' || value == null || (!isNaN(value) && value > 0)
			? ''
			: 'Giltighetstiden behöver vara större än 0';
	}

	onSave() {
		const store = this.state.store;
		store.storeId = this.state.isNew ? new Date().getTime() : store.storeId;
		store.synlig = this.state.synlig;
		store.referensButik = this.state.referensButik;
		const kedja = this.state.kedja ? { _key: this.state.kedja.id } : null;
		const block = this.state.block ? { _key: this.state.block.id } : null;
		Backend.saveStore({ butik: store, kedja, block }).then(
			() => {
				this.redirect('/stores');
			},
			() => {
				this.setState({
					snackbar: 'Ett fel uppstod vid sparningen av butiken',
				});
			},
		);
	}

	onDelete() {
		const store = this.state.store;

		// eslint-disable-next-line
		if (confirm(`Är du säker på att du vill ta bort ${store.namn}?`)) {
			Backend.deleteStore(store._key).then(
				() => {
					this.redirect('/stores');
				},
				() => {
					this.setState({
						snackbar: 'Ett fel uppstod vid borttagningen av butiken',
					});
				},
			);
		}
	}

	setProperty(key, value) {
		if (key == 'prisGiltighetstid' && value == '') {
			value = null;
		}
		const store = { ...this.state.store };
		store[key] = value;
		this.setState({ store });
	}

	render() {
		const store = this.state.store;
		return (
			<ViewContainer title={this.state.isNew ? 'Ny butik' : 'Ändra butik'}>
				<LoadingContainer
					className={classes.storeEditContainer}
					enabled={this.state.loading}
				>
					<Box
						className={classes.top}
						sx={{ gap: '2em' }}
					>
						<Box sx={{ display: 'flex', width: 'auto', flexDirection: 'column' }}>
							<TextField
								sx={{ marginBottom: '1em', width: '30em' }}
								value={store.namn || ''}
								onChange={(evt) => this.setProperty('namn', evt.target.value)}
								label={'Namn'}
								error={!store.namn}
								disabled={true}
							/>
							<TextField
								sx={{ marginBottom: '1em', width: '30em' }}
								value={store.info || ''}
								onChange={(evt) => this.setProperty('info', evt.target.value)}
								label={'Information'}
								rows={4}
								disabled={true}
								multiline
							/>
							{this.state.blocks && (
								<Autocomplete
									id="combo-box-block"
									value={this.state.block}
									options={this.state.blocks}
									sx={{ marginBottom: '1em', width: '30em' }}
									onChange={(_, item) => {
										this.setState({ block: item });
									}}
									disabled={true}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Block"
										/>
									)}
								/>
							)}
							{this.state.chains && (
								<Autocomplete
									id="combo-box-chain"
									value={this.state.kedja}
									options={this.state.chains}
									sx={{ marginBottom: '1em', width: '30em' }}
									onChange={(_, item) => {
										this.setState({ kedja: item });
									}}
									disabled={true}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Kedja"
										/>
									)}
								/>
							)}
							<label className={classes.inputLabel}>
								Färg
								<span
									className={classes.color}
									style={{ backgroundColor: store.fargkod }}
								/>
							</label>
							<br />
							<ChromePicker
								disableAlpha
								color={store.fargkod || '#000000'}
								onChange={(color) => this.setProperty('fargkod', color.hex)}
								disabled={true}
							/>
						</Box>
						<Box sx={{ display: 'flex', width: 'auto', flexDirection: 'column' }}>
							<TextField
								sx={{ marginBottom: '1em', width: '30em' }}
								value={store.ort || ''}
								onChange={(evt) => this.setProperty('ort', evt.target.value)}
								label={'Stad'}
								error={!store.ort && !this.state.referensButik}
								disabled={true}
							/>
							<TextField
								sx={{ marginBottom: '1em', width: '30em' }}
								value={store.postkod || ''}
								onChange={(evt) => this.setProperty('postkod', evt.target.value)}
								label={'Postnummer'}
								error={!store.postkod && !this.state.referensButik}
								disabled={true}
							/>
							<TextField
								sx={{ marginBottom: '1em', width: '30em' }}
								value={store.adress || ''}
								onChange={(evt) => this.setProperty('adress', evt.target.value)}
								label={'Adress'}
								error={!store.adress && !this.state.referensButik}
								disabled={true}
							/>
							<TextField
								sx={{ marginBottom: '1em', width: '30em' }}
								value={store.landskod || ''}
								onChange={(evt) => this.setProperty('landskod', evt.target.value)}
								label={'Landskod'}
								error={!store.landskod && !this.state.referensButik}
								disabled={true}
							/>
							<TextField
								sx={{ marginBottom: '1em', width: '30em' }}
								value={store.latitud || ''}
								type="number"
								onChange={(evt) => this.setProperty('latitud', evt.target.value)}
								label={'Latitud'}
								error={!store.latitud}
								disabled={true}
							/>
							<TextField
								sx={{ marginBottom: '1em', width: '30em' }}
								value={store.longitud || ''}
								type="number"
								onChange={(evt) => this.setProperty('longitud', evt.target.value)}
								label={'Longitud'}
								error={!store.longitud}
								disabled={true}
							/>
							<FormGroup>
								<FormControlLabel
									className={classes.inputLabelCheckbox}
									control={
										<Checkbox
											checked={this.state.synlig}
											onChange={() => this.setState({ synlig: !this.state.synlig })}
											disabled={true}
										/>
									}
									label="Synlig"
								/>
								<FormControlLabel
									className={classes.inputLabelCheckbox}
									control={
										<Checkbox
											checked={this.state.referensButik}
											onChange={() => this.setState({ referensButik: !this.state.referensButik })}
											disabled={true}
										/>
									}
									label="Referensbutik"
								/>
							</FormGroup>
							{/* <label className={classes.inputLabel}>Synlig</label>
							<div className={classes.visible}>
								<Checkbox
									checked={this.state.synlig}
									onChange={() => this.setState({ synlig: !this.state.synlig })}
								/>
							</div> */}
							<Box>
								<label className={classes.inputLabel}>Spindelinställningar</label>
							</Box>
							<TextField
								sx={{ marginBottom: '1em', marginTop: '1em', width: '30em' }}
								type={'number'}
								value={store.prisGiltighetstid || ''}
								onChange={(evt) => this.setProperty('prisGiltighetstid', evt.target.value)}
								label={'Giltighetstid pris (dagar)'}
								error={!!this.validatePrisGiltighetstid()}
								disabled={true}
							/>
							<TextField
								sx={{ marginBottom: '1em', marginTop: '1em', width: '30em' }}
								value={store.dailyPricesExternalId || ''}
								label={'ExternId'}
								onChange={(evt) => this.setProperty('externId', evt.target.value)}
								disabled={!this.state.editExternId}
							/>
							<FormGroup>
								<FormControlLabel
									className={classes.inputLabelCheckbox}
									control={
										<Checkbox
											checked={this.state.editExternId}
											onChange={(_) => this.setState({ editExternId: !this.state.editExternId })}
											disabled={true}
										/>
									}
									label="Jag vet vad jag gör och vill ändra ExternId"
								/>
							</FormGroup>
							<TextField
								sx={{ marginBottom: '1em', marginTop: '1em', width: '30em' }}
								value={store.oldButikKey || ''}
								label={'Gammal _key innan migrering'}
								disabled={!this.state.editExternId}
							/>
							{/* <label className={classes.inputLabel}>Jag vet vad jag gör och vill ändra ExternId</label>
							<div className={classes.visible}>
								<Checkbox
									checked={this.state.editExternId}
									onClick={(_) => this.setState({ editExternId: !this.state.editExternId })}
								/>
							</div> */}
						</Box>
					</Box>
				</LoadingContainer>
				<Box className="buttons">
					<Button
						sx={{ marginRight: '1em;' }}
						variant="outlined"
						onClick={this.back.bind(this)}
					>
						Avbryt
					</Button>
					<Button
						sx={{ marginRight: '1em;' }}
						variant="outlined"
						color="error"
						onClick={this.onDelete.bind(this)}
						disabled={true}
					>
						Ta bort
					</Button>
					<Button
						variant="contained"
						primary="true"
						onClick={this.onSave.bind(this)}
						disabled={true}
					>
						{this.state.isNew ? 'Skapa' : 'Spara'}
					</Button>
				</Box>

				<Snackbar
					open={!!this.state.snackbar}
					message={this.state.snackbar}
					autoHideDuration={3000}
					onClose={() => this.setState({ snackbar: '' })}
				/>
			</ViewContainer>
		);
	}
}

export default withRouter(StoreEdit);
